<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-form xs12 ref="form" lazy-validation v-model="isFormValid">
        <v-layout row wrap v-for="(closure, i) in closures" v-bind:key="i">
          <v-layout flex xs12 justify-space-between>
            <v-flex sm4>
              <v-text-field v-model="closure.name" label="Closure Reason" :rules="nameRules" />
            </v-flex>
            <v-btn flat color="#090c9b" v-on:click="closures.splice(i, 1)">
              <v-icon class="mr-1" left>mdi-delete-outline</v-icon>REMOVE
            </v-btn>
          </v-layout>
          <v-layout flex xs12 nowrap align-center>
            <v-flex xs3>
              <p class="title font-weight-light">Station will close at</p>
            </v-flex>
            <v-flex xs2>
              <time-select
                :label="timeLabel"
                v-bind:value.sync="closure.closeTime"
                :rules="requiredField"
                autocomplete
              ></time-select>
            </v-flex>
            <v-flex mr-3 ml-3 shrink>
              <p class="title font-weight-light">on</p>
            </v-flex>
            <v-flex xs4>
              <v-menu
                ref="menu"
                v-model="closure.showCloseDate"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-text-field
                      :value="formatLocaleDate(closure.closeDate)"
                      readonly
                      class="DateStyle border-label-input"
                      persistent-hint
                      outline
                      label="Date"
                      :rules="requiredField"
                      append-icon="mdi-calendar-blank"
                    ></v-text-field>
                  </span>
                </template>
                <v-date-picker
                  v-model="closure.closeDate"
                  @input="onCloseDateChange(closure)"
                  :min="today"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout flex xs12 nowrap align-center>
            <v-flex xs3>
              <p class="title font-weight-light">Station will re-open at</p>
            </v-flex>
            <v-flex xs2>
              <time-select
                :label="timeLabel"
                v-bind:value.sync="closure.reopenTime"
                :rules="requiredField"
                autocomplete
              ></time-select>
            </v-flex>
            <v-flex mr-3 ml-3 shrink>
              <p class="title font-weight-light">on</p>
            </v-flex>
            <v-flex xs4>
              <v-menu
                ref="menu"
                v-model="closure.showReopenDate"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-text-field
                      :value="formatLocaleDate(closure.reopenDate)"
                      readonly
                      class="DateStyle border-label-input"
                      persistent-hint
                      outline
                      label="Date"
                      :rules="requiredField"
                      append-icon="mdi-calendar-blank"
                    ></v-text-field>
                  </span>
                </template>
                <v-date-picker
                  v-model="closure.reopenDate"
                  @input="closure.showReopenDate = false"
                  :min="getMinReopenDate(closure.closeDate)"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-divider class="flex xs12 mt-4" v-if="i < closures.length - 1"></v-divider>
        </v-layout>
        <v-btn
          flat
          color="#0d73d8"
          class="ml-0 pl-0"
          v-on:click="closures.push(getDefaultClosure())"
        >
          <v-icon class="mr-1">mdi-plus</v-icon>Add Station Closure
        </v-btn>
      </v-form>
      <v-flex xs12 style="position: sticky; bottom: 0;">
        <v-btn
          class="mt-4"
          color="primary rounded m-2"
          v-if="isFormDirty"
          @click="saveCalendar"
          style="float: right;"
          :loading="loading"
        >
          <v-icon class="mr-3">mdi-content-save</v-icon>Save
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import DateTime from 'luxon/src/datetime.js';
import rules from '@/rules';
import timeSelect from '@/components/timeSelect';
import { CalendarTypes } from '@/constants';

export default {
  name: 'ClosureHours',
  props: ['isDirty'],

  async beforeRouteLeave(to, from, next) {
    if (this.isFormDirty) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data: () => ({
    defaultClosure: {
      name: '',
      closeTime: '00:00',
      closeDate: DateTime.local().toISODate(),
      showCloseDate: false,
      reopenTime: '00:00',
      reopenDate: DateTime.local()
        .plus({ days: 1 })
        .toISODate(),
      showReopenDate: false,
    },
    isFormValid: false,
    closures: [],
    loading: true,
    nameRules: [rules.required('Closure reason is required')],
    requiredField: [rules.required('This is a required Field')],
  }),
  computed: {
    ...mapState('sites', ['active_brand', 'active_site']),
    ...mapState('calendar', ['isActiveBrandCalendarFetched']),
    ...mapGetters('calendar', {
      getEvents: 'getEvents',
    }),

    today() {
      return DateTime.local().toISODate();
    },
    timeLabel() {
      return `Time (${(this.active_brand && this.active_brand.timezone) || 'UTC'})`;
    },
    isFormDirty() {
      return !isEqual(
        this.convertEventsToClosures(this.getEvents(CalendarTypes.closure)),
        this.closures,
      );
    },
  },
  watch: {
    isActiveBrandCalendarFetched: {
      async handler(value) {
        this.loading = true;
        if (!value) return;
        this.closures = this.convertEventsToClosures(this.getEvents(CalendarTypes.closure));
        this.loading = false;
      },
      immediate: true,
    },
  },
  methods: {
    getDefaultClosure() {
      return cloneDeep(this.defaultClosure);
    },
    ...mapActions('calendar', { saveEvents: 'saveEvents' }),
    formatLocaleDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
    },
    getMinReopenDate(date) {
      return DateTime.fromISO(date)
        .plus({ minutes: 1 })
        .toISODate();
    },
    onCloseDateChange(closure) {
      closure.showCloseDate = false;
      const closeDatePlusDay = DateTime.fromISO(closure.closeDate).plus({ days: 1 });
      const reopenDate = DateTime.fromISO(closure.reopenDate);
      if (closeDatePlusDay > reopenDate) {
        closure.reopenDate = closeDatePlusDay.toISODate();
      }
    },
    convertEventsToClosures(dayspanEvents) {
      return dayspanEvents.map((event) => {
        if (!event.schedule.start || !event.schedule.end) {
          return {};
        }
        const dtStart = DateTime.fromMillis(event.schedule.start).setZone(
          this.active_brand.timezone,
        );
        const dtEnd = DateTime.fromMillis(event.schedule.end).setZone(this.active_brand.timezone);

        return {
          name: event.data.title,
          closeTime: dtStart.toFormat('HH:mm'),
          closeDate: dtStart.toFormat('yyyy-LL-dd'),
          showCloseDate: false,
          reopenTime: dtEnd.toFormat('HH:mm'),
          reopenDate: dtEnd.toFormat('yyyy-LL-dd'),
          showReopenDate: false,
        };
      });
    },
    convertClosuresToEvents(closures) {
      return closures.map((closure) => {
        const dtEventStart = DateTime.fromFormat(
          `${closure.closeDate} ${closure.closeTime}`,
          'yyyy-LL-dd HH:mm',
          { zone: `${this.active_brand.timezone}` },
        ); // .setZone(this.active_brand.timezone);
        const dtEventEnd = DateTime.fromFormat(
          `${closure.reopenDate} ${closure.reopenTime}`,
          'yyyy-LL-dd HH:mm',
          { zone: `${this.active_brand.timezone}` },
        ); // .setZone(this.active_brand.timezone);

        return {
          schedule: {
            duration: 1,
            start: dtEventStart.valueOf(),
            end: dtEventEnd.valueOf(),
          },
          data: {
            title: closure.name,
            color: '#ff8c84',
            calendar: CalendarTypes.closure,
          },
        };
      });
    },
    async saveCalendar() {
      const v = this.$refs.form.validate();
      if (!this.isFormValid || !v) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      const closureEvents = this.convertClosuresToEvents(this.closures);
      try {
        this.loading = true;
        await this.saveEvents({ events: closureEvents, type: CalendarTypes.closure });
        this.$store.dispatch('sites/fetchSite', {
          id: this.active_site.id,
          nocache: 1,
        });
      } catch (error) {
        this.$toast.error('Error saving closures hours');
        return;
      }

      this.loading = false;
      this.$toast('Closure hours saved');
    },
  },
  components: { timeSelect },
};
</script>

<style></style>
