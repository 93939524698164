import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VForm,
            {
              ref: "form",
              attrs: { xs12: "", "lazy-validation": "" },
              model: {
                value: _vm.isFormValid,
                callback: function($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _vm._l(_vm.closures, function(closure, i) {
                return _c(
                  VLayout,
                  { key: i, attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      VLayout,
                      {
                        attrs: {
                          flex: "",
                          xs12: "",
                          "justify-space-between": ""
                        }
                      },
                      [
                        _c(
                          VFlex,
                          { attrs: { sm4: "" } },
                          [
                            _c(VTextField, {
                              attrs: {
                                label: "Closure Reason",
                                rules: _vm.nameRules
                              },
                              model: {
                                value: closure.name,
                                callback: function($$v) {
                                  _vm.$set(closure, "name", $$v)
                                },
                                expression: "closure.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          VBtn,
                          {
                            attrs: { flat: "", color: "#090c9b" },
                            on: {
                              click: function($event) {
                                return _vm.closures.splice(i, 1)
                              }
                            }
                          },
                          [
                            _c(
                              VIcon,
                              { staticClass: "mr-1", attrs: { left: "" } },
                              [_vm._v("mdi-delete-outline")]
                            ),
                            _vm._v("REMOVE\n          ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      VLayout,
                      {
                        attrs: {
                          flex: "",
                          xs12: "",
                          nowrap: "",
                          "align-center": ""
                        }
                      },
                      [
                        _c(VFlex, { attrs: { xs3: "" } }, [
                          _c("p", { staticClass: "title font-weight-light" }, [
                            _vm._v("Station will close at")
                          ])
                        ]),
                        _c(
                          VFlex,
                          { attrs: { xs2: "" } },
                          [
                            _c("time-select", {
                              attrs: {
                                label: _vm.timeLabel,
                                value: closure.closeTime,
                                rules: _vm.requiredField,
                                autocomplete: ""
                              },
                              on: {
                                "update:value": function($event) {
                                  return _vm.$set(closure, "closeTime", $event)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          VFlex,
                          { attrs: { "mr-3": "", "ml-3": "", shrink: "" } },
                          [
                            _c(
                              "p",
                              { staticClass: "title font-weight-light" },
                              [_vm._v("on")]
                            )
                          ]
                        ),
                        _c(
                          VFlex,
                          { attrs: { xs4: "" } },
                          [
                            _c(
                              VMenu,
                              {
                                ref: "menu",
                                refInFor: true,
                                attrs: {
                                  "close-on-content-click": false,
                                  "nudge-right": 40,
                                  lazy: "",
                                  transition: "scale-transition",
                                  "full-width": "",
                                  "min-width": "290px"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(VTextField, {
                                                staticClass:
                                                  "DateStyle border-label-input",
                                                attrs: {
                                                  value: _vm.formatLocaleDate(
                                                    closure.closeDate
                                                  ),
                                                  readonly: "",
                                                  "persistent-hint": "",
                                                  outline: "",
                                                  label: "Date",
                                                  rules: _vm.requiredField,
                                                  "append-icon":
                                                    "mdi-calendar-blank"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: closure.showCloseDate,
                                  callback: function($$v) {
                                    _vm.$set(closure, "showCloseDate", $$v)
                                  },
                                  expression: "closure.showCloseDate"
                                }
                              },
                              [
                                _c(VDatePicker, {
                                  attrs: { min: _vm.today },
                                  on: {
                                    input: function($event) {
                                      return _vm.onCloseDateChange(closure)
                                    }
                                  },
                                  model: {
                                    value: closure.closeDate,
                                    callback: function($$v) {
                                      _vm.$set(closure, "closeDate", $$v)
                                    },
                                    expression: "closure.closeDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      VLayout,
                      {
                        attrs: {
                          flex: "",
                          xs12: "",
                          nowrap: "",
                          "align-center": ""
                        }
                      },
                      [
                        _c(VFlex, { attrs: { xs3: "" } }, [
                          _c("p", { staticClass: "title font-weight-light" }, [
                            _vm._v("Station will re-open at")
                          ])
                        ]),
                        _c(
                          VFlex,
                          { attrs: { xs2: "" } },
                          [
                            _c("time-select", {
                              attrs: {
                                label: _vm.timeLabel,
                                value: closure.reopenTime,
                                rules: _vm.requiredField,
                                autocomplete: ""
                              },
                              on: {
                                "update:value": function($event) {
                                  return _vm.$set(closure, "reopenTime", $event)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          VFlex,
                          { attrs: { "mr-3": "", "ml-3": "", shrink: "" } },
                          [
                            _c(
                              "p",
                              { staticClass: "title font-weight-light" },
                              [_vm._v("on")]
                            )
                          ]
                        ),
                        _c(
                          VFlex,
                          { attrs: { xs4: "" } },
                          [
                            _c(
                              VMenu,
                              {
                                ref: "menu",
                                refInFor: true,
                                attrs: {
                                  "close-on-content-click": false,
                                  "nudge-right": 40,
                                  lazy: "",
                                  transition: "scale-transition",
                                  "full-width": "",
                                  "min-width": "290px"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(VTextField, {
                                                staticClass:
                                                  "DateStyle border-label-input",
                                                attrs: {
                                                  value: _vm.formatLocaleDate(
                                                    closure.reopenDate
                                                  ),
                                                  readonly: "",
                                                  "persistent-hint": "",
                                                  outline: "",
                                                  label: "Date",
                                                  rules: _vm.requiredField,
                                                  "append-icon":
                                                    "mdi-calendar-blank"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: closure.showReopenDate,
                                  callback: function($$v) {
                                    _vm.$set(closure, "showReopenDate", $$v)
                                  },
                                  expression: "closure.showReopenDate"
                                }
                              },
                              [
                                _c(VDatePicker, {
                                  attrs: {
                                    min: _vm.getMinReopenDate(closure.closeDate)
                                  },
                                  on: {
                                    input: function($event) {
                                      closure.showReopenDate = false
                                    }
                                  },
                                  model: {
                                    value: closure.reopenDate,
                                    callback: function($$v) {
                                      _vm.$set(closure, "reopenDate", $$v)
                                    },
                                    expression: "closure.reopenDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    i < _vm.closures.length - 1
                      ? _c(VDivider, { staticClass: "flex xs12 mt-4" })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                VBtn,
                {
                  staticClass: "ml-0 pl-0",
                  attrs: { flat: "", color: "#0d73d8" },
                  on: {
                    click: function($event) {
                      _vm.closures.push(_vm.getDefaultClosure())
                    }
                  }
                },
                [
                  _c(VIcon, { staticClass: "mr-1" }, [_vm._v("mdi-plus")]),
                  _vm._v("Add Station Closure\n      ")
                ],
                1
              )
            ],
            2
          ),
          _c(
            VFlex,
            {
              staticStyle: { position: "sticky", bottom: "0" },
              attrs: { xs12: "" }
            },
            [
              _vm.isFormDirty
                ? _c(
                    VBtn,
                    {
                      staticClass: "mt-4",
                      staticStyle: { float: "right" },
                      attrs: {
                        color: "primary rounded m-2",
                        loading: _vm.loading
                      },
                      on: { click: _vm.saveCalendar }
                    },
                    [
                      _c(VIcon, { staticClass: "mr-3" }, [
                        _vm._v("mdi-content-save")
                      ]),
                      _vm._v("Save\n      ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }